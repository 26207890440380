import type { NextPage } from "next";
import React, { useState, useRef } from "react";
import ProposalCardFeed from "../../components/proposalCardFeed/ProposalCardFeed.view";
import PageTitle from "../../components/pageTitle";
import ProposalDetails from "../../components/proposalDetails/ProposalDetails.view";

import styles from "./Proposals.module.css";
import { useRouter } from "next/router";
import Page from "../../components/page";
import { Routes } from "../../global/constants";
import { conditionalRender } from "../../utils/helpers";
import EmptyPageView, { EmptyViewType } from "../../components/emptyPageView";
import MoonLoader from "react-spinners/MoonLoader";
import { colour } from "../../global/theme";
import RoundedButton from "../../roundedButton";
import BackgroundOverlay from "../../components/backgroundOverlayView";
import CommitSuccessView from "../../components/commitSuccessView";
import { useIsMobileWidth } from "../../global/useIsMobileWidth";
import { useProposalsData } from "../../hooks/useProposalsData/useProposalsData";
import Footer from "../../components/footer/Footer.view";

const ProposalsView: NextPage = () => {
  const { isMobileWidth } = useIsMobileWidth();

  const router = useRouter();
  const [showCommitSuccessView, setShowCommitSuccessView] = useState(false);
  const successAnimationCount = useRef<number>(0);
  const {
    proposals,
    isLoading,
    activeProposalId,
    toggleProposalIsActive,
    refetch,
    hasError,
  } = useProposalsData();
  const proposalsExist = proposals?.length !== 0;

  const loadingView = () => {
    return (
      <div className={styles.loading}>
        <MoonLoader color={colour.interactive} loading={isLoading} size={40} />
      </div>
    );
  };

  const onCommitProposalSuccess = () => {
    setShowCommitSuccessView(true);
  };

  const onCommitSuccessAnimationEnd = () => {
    setShowCommitSuccessView(false);
    successAnimationCount.current = successAnimationCount?.current + 1;
    // Ref hack used as Lottie library runs the animation twice so we need to only
    // route back to proposal screen on the second animation end as the first one runs on page load
    if (successAnimationCount?.current > 1) {
      refetch();
    }
  };

  const onAddIdeaPress = () => {
    router.push(Routes.CreateProposal);
  };

  const routeToProposalDetails = (proposalId: string) => {
    router.push(`${Routes.Proposal}?proposalId=${proposalId}`);
  };

  return (
    <Page>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.titleContainer}>
            <PageTitle title={"My Entries"} />
            {conditionalRender(
              proposalsExist,
              <RoundedButton
                onClick={onAddIdeaPress}
                text="Create Entry"
                wide={false}
              />
            )}
          </div>
          {!isLoading && (
            <h3 className={styles.subtitle}>
              You have {proposals.length} entries
            </h3>
          )}
          <div className={styles.bodyContainer}>
            {conditionalRender(
              proposalsExist,
              <>
                <div className={styles.proposalFeedContainer}>
                  <ProposalCardFeed
                    proposals={proposals}
                    activeProposalId={activeProposalId}
                    toggleProposalIsActive={
                      isMobileWidth
                        ? routeToProposalDetails
                        : toggleProposalIsActive
                    }
                    isActiveOverride={isMobileWidth}
                  />
                </div>
                <div className={styles.proposalDetailsContainer}>
                  {proposals.length > 1 && (
                    <ProposalDetails
                      //TODO: Remove refetch once commit endpoint returns proposal
                      onCommitSuccess={onCommitProposalSuccess}
                      proposal={
                        proposals.find((x) => x.id === activeProposalId) ??
                        proposals[0]
                      }
                    />
                  )}
                </div>
              </>,
              conditionalRender(
                isLoading,
                loadingView(),
                conditionalRender(
                  !hasError,
                  <EmptyPageView onButtonPress={onAddIdeaPress} />,
                  <EmptyPageView
                    type={EmptyViewType.Error}
                    onButtonPress={refetch}
                  />
                )
              )
            )}
          </div>
        </div>
        <Footer />
      </div>
      <BackgroundOverlay show={showCommitSuccessView} />
      <CommitSuccessView
        show={showCommitSuccessView}
        onAnimationEnd={onCommitSuccessAnimationEnd}
      />
    </Page>
  );
};

export default ProposalsView;
