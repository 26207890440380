import { Proposal } from "../../types/proposal";
import ProposalCard from "../proposalCard";

interface ProposalFeedProps {
  proposals: Proposal[];
  toggleProposalIsActive: (proposalId: string) => void;
  isActiveOverride: boolean;
  activeProposalId: string | null;
}

const ProposalCardFeed = (props: ProposalFeedProps) => {
  return (
    <>
      {props.proposals.map((proposal, index) => (
        <ProposalCard
          id={proposal.id}
          key={proposal.id}
          name={proposal.name}
          creator={proposal.creator}
          isActive={
            proposal.id === props.activeProposalId ||
            props.isActiveOverride ||
            (index === 0 && !props.activeProposalId)
          }
          toggleIsActive={() => props.toggleProposalIsActive(proposal.id)}
          dateCreated={proposal.dateCreated}
          description={proposal.description}
          fileLength={proposal.files.length}
          status={proposal.status}
          disableThumbnail
        />
      ))}
    </>
  );
};

export default ProposalCardFeed;
