import { ProposalCardProps } from "./ProposalCard.entities";
import styles from "./ProposalCard.module.css";
import BaseText, { FontWeight, TextSize } from "../baseText";
import { ErrorIcon } from "../icons";
import { colour } from "../../global/theme";
import { ProposalStatus } from "../../types/proposal";
import { useMemo } from "react";

const WARNING_ICON_SIZE = 20;

const ProposalCard = (props: ProposalCardProps) => {
  const classList = [styles.proposalCard];
  if (props.isActive) {
    classList.push(styles.isActive);
  }
  if (props.outlined) {
    classList.push(styles.outlined);
  }
  const getFormattedDate = () => {
    return new Date(props.dateCreated).toLocaleDateString("en-UK");
  };

  const getIntials = () => {
    //TODO: return the last 4 characters of the creator's public key
    if (props.creator.startsWith("0x")) {
      return `${props.creator.slice(0, 2)}...${props.creator.slice(-4)}`;
    }
    return props.creator
      .split(" ")
      .map((n) => n[0])
      .join("");
  };

  const renderStatus = () => {
    if (props.status === ProposalStatus.Confirmed) return null;
    return (
      <div className={styles.warningContainer}>
        <ErrorIcon
          height={WARNING_ICON_SIZE}
          width={WARNING_ICON_SIZE}
          backgroundFill={colour.warning}
          lineFill={colour.black}
        />
      </div>
    );
  };

  const pastelColor = useMemo(() => {
    const hue = Math.floor(Math.random() * 360);
    return `hsl(${hue}, 70%, 85%)`;
  }, []);

  const handleImageError = (
    e: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    e.currentTarget.style.display = "none";
    e.currentTarget.nextElementSibling?.removeAttribute("style");
  };

  return (
    <div className={classList.join(" ")} onClick={props.toggleIsActive}>
      {!props.disableThumbnail && (
        <div className={styles.imageContainer}>
          {props.thumbnailUrl && (
            <img
              src={props.thumbnailUrl}
              alt=""
              className={styles.proposalImage}
              onError={handleImageError}
            />
          )}
          <div
            className={styles.placeholderImage}
            style={
              props.thumbnailUrl
                ? { display: "none" }
                : { backgroundColor: pastelColor }
            }
          >
            B
          </div>
        </div>
      )}
      <div className={styles.contentContainer}>
        <div className={styles.row}>
          <BaseText
            text={props.name}
            size={TextSize.Large}
            fontWeight={FontWeight.Bold}
            className={styles.marginRightAuto}
          />
          {renderStatus()}
          <div className={styles.initials}>
            <BaseText
              text={getIntials()}
              size={TextSize.SuperMini}
              fontWeight={FontWeight.SemiBold}
              singleLine
              style={{ textOverflow: "clip", margin: 0, lineHeight: 2 }}
            />
          </div>
        </div>
        {props.description && (
          <BaseText
            text={`${props.description}`}
            size={TextSize.Small}
            className={styles.bottomMargin}
          />
        )}
        {props.hashedContents && (
          <BaseText
            text={`Hash: ${props.hashedContents}`}
            size={TextSize.Small}
            className={styles.bottomMargin}
            singleLine
          />
        )}
        <div className={styles.row}>
          <BaseText
            text={getFormattedDate()}
            size={TextSize.Small}
            className={styles.marginRightAuto}
          />
          {props.fileLength > 0 && (
            <BaseText
              text={`No. of files: ${props.fileLength}`}
              size={TextSize.Small}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProposalCard;
